a {
  color: black;
  font-family: "Work Sans";
  line-height: 4rem;
  margin: 2rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 5px;
  width: 100vw;
  h1 {
    font-size: 3rem;
    font-family: "Work Sans";
    margin: 0;
    padding: 0;
  }
  ul {
    list-style: none;
    max-width: 500px;
  }
  .article {
    display: flex;
    flex-direction: row;
    h5 {
      margin-left: 1rem;
    }
  }
}
